<template>
  <main>
    <b-overlay :show="loading">
      <b-tabs>
        <b-tab title="Daftar Rekening">
          <b-card no-body>
            <b-card-body>
              <b-card-title>
                <b-row class="justify-content-end">
                  <b-col>
                    <div align="right">
                      <b-button
                        variant="outline-primary"
                        v-b-modal.modal-sm
                        @click="ModalShow()"
                        class="btn-icon"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          class="mr-25"
                        />Tambah</b-button
                      >
                    </div>
                    <b-modal
                      v-model="Modal"
                      id="modal-tambah"
                      centered
                      size="sm"
                      :title="titleModal"
                    >
                      <b-card-text>
                        <b-form>
                          <b-row>
                            <b-col md="12">
                              <b-form-group
                                label="Kode Bank"
                                label-for="kode_bank"
                              >
                                <b-form-input
                                  id="kode_bank"
                                  v-model="form.code"
                                  placeholder="Kode Bank"
                                  type="number"
                                />
                              </b-form-group>
                              <b-form-group label="Nama Bank" label-for="name">
                                <b-form-input
                                  id="name"
                                  v-model="form.name"
                                  placeholder="Nama Bank"
                                />
                              </b-form-group>
                              <b-form-group
                                label="Nomor Rekening"
                                label-for="number"
                              >
                                <b-form-input
                                  id="number"
                                  v-model="form.number"
                                  placeholder="Nomor Rekening"
                                  type="number"
                                />
                              </b-form-group>
                              <b-form-group
                                label="Informasi"
                                label-for="information"
                              >
                                <b-form-input
                                  id="information"
                                  v-model="form.information"
                                  placeholder="Informasi"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-form>
                      </b-card-text>

                      <template #modal-footer>
                        <div class="w-100">
                          <p class="float-left mb-0"></p>

                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="outline-success"
                            class="mr-1 btn-icon"
                            @click.prevent="Tambah"
                          >
                            <feather-icon icon="SaveIcon" class="mr-25" />{{
                              label || "Tambah"
                            }}
                          </b-button>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-primary"
                            class="float-right btn-icon"
                            @click.prevent="tutupModal"
                          >
                            <feather-icon
                              icon="LogOutIcon"
                              class="mr-25"
                            />Tutup
                          </b-button>
                        </div>
                      </template>
                    </b-modal>
                  </b-col></b-row
                >
              </b-card-title>
              <b-card-text class="blog-content-truncate">
                <b-row>
                  <b-col md="3" sm="4" class="my-1">
                    <b-form-group class="mb-0">
                      <label class="d-inline-block text-sm-left mr-50"
                        >Per page</label
                      >
                      <b-form-select
                        id="perPageSelect"
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        class="w-50"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4" sm="8" class="my-1">
                    <b-form-group
                      label="Sort"
                      label-cols-sm="2"
                      label-align-sm="right"
                      label-size="sm"
                      label-for="sortBySelect"
                      class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-select
                          id="sortBySelect"
                          v-model="sortBy"
                          :options="sortOptions"
                          class="w-50"
                        >
                          <template v-slot:first>
                            <option value="">-- none --</option>
                          </template>
                        </b-form-select>
                        <b-form-select
                          v-model="sortDesc"
                          size="sm"
                          :disabled="!sortBy"
                          class="w-30"
                        >
                          <option :value="false">Asc</option>
                          <option :value="true">Desc</option>
                        </b-form-select>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="5" class="my-1">
                    <b-form-group label-for="filterInput" class="mb-0">
                      <b-input-group size="sm">
                        <b-form-input
                          id="filterInput"
                          v-model="filter"
                          type="search"
                          placeholder="Search......"
                        />
                        <b-input-group-append>
                          <b-button :disabled="!filter" @click="filter = ''">
                            Clear
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col md="2" class="my-1">
                    <b-button variant="primary" size="sm"
                      ><feather-icon icon="TrashIcon" />Multiple Delete</b-button
                    >
                  </b-col> -->

                  <b-col cols="12">
                    <b-table
                      striped
                      small
                      hover
                      responsive
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                      :fields="fields"
                      :items="rekeningData"
                    >
                      <!-- A virtual column -->
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>

                      <!-- A custom formatted column -->
                      <template #cell(action)="row">
                        <b-dropdown
                          variant="link"
                          toggle-class="text-decoration-none"
                          no-caret
                        >
                          <template v-slot:button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="text-body align-middle mr-25"
                            />
                          </template>
                          <!-- <b-dropdown-item :to="{ path: `paket-tryout/detail/${row.item.id}` }">
                              <feather-icon icon="EyeIcon" class="mr-50" />
                              <span>Detail</span>
                            </b-dropdown-item> -->
                          <b-dropdown-item @click="ModalUbah(row.item)">
                            <feather-icon icon="Edit2Icon" class="mr-50" />
                            <span>Edit</span>
                          </b-dropdown-item>
                          <b-dropdown-item @click="ModalHapus(row.item)">
                            <feather-icon icon="TrashIcon" class="mr-50" />
                            <span>Hapus</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </template>

                      <!-- Optional default data cell scoped slot -->
                      <template #cell(status)="data">
                        <b-badge v-if="data.item.status == 1" variant="success">
                          Aktif
                        </b-badge>
                        <b-badge v-if="data.item.status == 0" variant="danger">
                          Tidak Aktif
                        </b-badge>
                      </template>
                    </b-table>
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="center"
                      size="sm"
                      class="my-0"
                    />
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-tab>
        <b-tab title="Pengaturan Timer">
          <b-card class="mb-2">
            <strong class="d-block">
              Atur waktu <i class="text-danger">expired time</i> untuk setiap
              transaksi Paket, Ebook, dan SNBP disini.
            </strong>
          </b-card>
          <b-card>
            <b-row>
              <b-col sm="12" md="6" lg="4">
                <b-form-group label="Nilai (dalam satuan jam)">
                  <b-form-input type="number" v-model="timerManualTf" />
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6" lg="4">
                <b-form-group label="-">
                  <b-button
                    variant="primary"
                    @click.prevent="updateTimerManualTf"
                    >Simpan</b-button
                  >
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-tab>
      </b-tabs>
    </b-overlay>
  </main>
</template>

<script>
import {
  BOverlay,
  BTabs,
  BTab,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadio,
  BDropdownItem,
  BDropdown,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BDropdownItem,
    BDropdown,
    BOverlay,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadio,
    ToastificationContent,
    quillEditor,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      rekeningData: [],
      form: {
        id: null,
        name: null,
        number: null,
        code: null,
        information: null,
      },
      //select
      optStatus: [
        { value: "1", text: "Aktif" },
        { value: "0", text: "Tidak Aktif" },
        // { value: "faq", text: "FAQ" },
      ],
      //Modal
      titleModal: null,
      Modal: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "index", label: "No" },
        { key: "code", label: "Kode", sortable: true },
        { key: "name", label: "Nama Bank", sortable: true },
        { key: "number", label: "Nomor Rekening", sortable: true },
        { key: "information", label: "Informasi", sortable: true },
        {
          key: "expire_per_hour",
          label: "Batas Waktu Pembayaran (per jam)",
          sortable: true,
        },
        { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "name", value: "Rekening" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Rekening",
      search: null,
      idd: null,
      loading: false,
      timerManualTf: 0,
    };
  },
  watch: {},
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    async updateTimerManualTf() {
      if (!this.timerManualTf || this.timerManualTf < 1) {
        const message =
          this.timerManualTf < 1
            ? "Harap isi nilai timer lebih dari 0"
            : "Harap isi nilai timer!";
        this.displayError({
          message,
        });
        return false;
      }

      const payload = {
        value: this.timerManualTf,
      };

      try {
        this.loading = true;
        await this.$store.dispatch("rekening/updateTimer", payload);
        await this.getData();
        this.getTimerPayment();
        this.displaySuccess({
          message: "Timer berhasil diperbaharui",
        });
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    tutupModal() {
      this.Modal = false;
      this.resetForm();
      this.getData();
    },
    resetForm() {
      this.form = {
        id: null,
        name: null,
        code: null,
        number: null,
        information: null,
        expire_per_hour: null,
      };
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Rekening";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Rekening";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 1; //soft delete
          this.$store
            .dispatch("rekening/createUpdateDelete", [data])
            .then(() => {
              this.getData();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });

              this.$swal({
                icon: "success",
                title: "Terhapus!",
                text: "Data berhasil dihapus.",
                // timer: 1000,
                allowOutsideClick: true,
                customClass: {
                  confirmButton: "btn btn-success",
                },
                buttonsStyling: false,
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (this.form.name == null || this.form.name == "") {
        this.pesanGagal();
        return false;
      }
      let payload = {
        name: this.form.name,
        code: this.form.code,
        number: this.form.number,
        information: this.form.information,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("rekening/createUpdateDelete", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.resetForm();
          this.getData();
          this.displaySuccess({
            text: "Rekening berhasil di" + this.activeAction,
          });
          this.Modal = false;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getData() {
      this.loading = true;
      const payload = {};
      this.$store
        .dispatch("rekening/index", payload)
        .then((response) => {
          this.loading = false;
          let rekeningData = response.data.data;
          this.rekeningData = rekeningData;
          this.totalRows = this.rekeningData.length;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        })
        .finally(() => {
          this.selected = [];
        });
    },
    async getTimerPayment() {
      try {
        const setting = await this.$store.dispatch("rekening/getTimer");
        this.timerManualTf = setting?.value;
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
  },
  async mounted() {
    this.getData();
    this.getTimerPayment();
  },
};
</script>

<style></style>
